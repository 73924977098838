$.ajax({
    url: countryjsonurl,
    type:'get',
    dataType: 'json',
    success: function(response)
    {
        var options = "";
        $.each(response, function(index, value){
            options += "<option data-index="+index+' value="'+value.code+'" data-phone-code="'+value.dial_code+'" data-country-id="'+index+'" data-country-name="'+value.name+'">'+value.code+'&emsp;&emsp; - &emsp;&emsp;'+value.name+'</option>';
        });
        $('select.country-code').html(options).trigger('change');
    }
});
 
$("select.country-code").on('change', function(e){
    
    var phonecode = $(this).find(':selected').data('phone-code');
    $(this).closest('.phonecode-field').find('span.prefix').text(phonecode);
    var prefix = $(this).closest('.phonecode-field').find('span.prefix').text();
    $(this).closest('.phonecode-field').find('input.telephone').val('').trigger('change');
    $(this).closest('.phonecode-field').find('input.phonenumber').val(prefix);
    $(this).closest('.phonecode-field').find('input.phonecode').val(phonecode);
    var iso =$(this).closest('.phonecode-field .country-code').find(":selected").val();
    iso = iso.toLowerCase();
    $(this).closest('.form').find('input[name=country]').val(iso);
    // console.log(iso);
    // $(this).closest('.form').find('input[name=iso]').val(iso);


});
$('input.telephone').on('focusout', function(event){
    var prefix = $(this).closest('.phonecode-field').find('span.prefix').text();
    var phonecode =  $(this).closest('.phonecode-field').find(':selected').data('phone-code');
    var data = $(this).val();
    if(data.startsWith('0'))
    {
        data = data.substring(1,data.length);
    }
    var number = prefix + data;
    $(this).closest('.phonecode-field').find('input.phonenumber').val(number);
    $(this).closest('.phonecode-field').find('input.phonecode').val(phonecode);
});



window.onscroll = function() {headerScroll()};

var header = document.getElementById("myHeader");
var sticky = header.offsetTop;
function headerScroll() {
  if (window.pageYOffset > sticky) {
      if(!$(header).hasClass("fixed-header"))
      {
        header.classList.add("fixed-header");
        header.classList.add("slideInDown");

      }
    $("#dropdown-menu").hide();
  } else {
    header.classList.remove("fixed-header");
    header.classList.remove("slideInDown");
  }
}
$(document).ready(function () {
    $("#dropdown-menu .topic-list").on('click',function(e){
        e.preventDefault();
        var target = $(this).data("target");
        $(".course-menu-list").hide();
        $("#"+target).css("display","flex");
        $("#dropdown-menu .topic-list").removeClass('topic-active');
        $(this).addClass("topic-active");
    });
    $("#dropdown-menu .topic-list").first().trigger('click');
    if ( $(window).width() <= 767) {
            $('#mode').click(function() {
            $('.filter-bar').toggle();
            $("i", this).toggleClass("fa-chevron-down fa-chevron-up");
            });
        }
        if ( $(window).width() <= 767) {
            $('#fil').click(function() {
            $('.form').toggle();
            $("i", this).toggleClass("fa-chevron-down fa-chevron-up");
            });
        }
   
    $('body').on('click',function(){
        var element = event.target;
        if($(element).closest("#dropdown-menu").length > 0)
        {
            return;
        }
        $('#dropdown-menu').hide();
    });

    $("#menucourses").click(function(event) {
        if($("#myHeader").hasClass("fixed-header"))
        {
            window.location.href = $(this).attr('href');
            return true;
        }
        event.preventDefault();
        if (window.matchMedia("(max-width: 767px)").matches){
            $(location).attr('href', $(this).attr('href'));
            $("#dropdown-menu").hide();
            return true;
        }
        else{
            $("#dropdown-menu").toggle(); 
        }
       
        event.stopPropagation();
    });
    $("#dropdown-menu a").on('click', function() {
        var go_to_url = $(this).attr("href");
        window.location.href = go_to_url;
    });
    $(".text-toggle").each(function() {
        var textDivHeight = $(this).height();
        var maxHeight = $(this).css('max-height');
        maxHeight = parseInt(maxHeight);
        elementId = $(this).attr('id');
        console.log('max ht :'+maxHeight+" ht :"+textDivHeight+" id : "+elementId);
        if(textDivHeight < maxHeight){
        
            $(this).next().css("display","none");
        } else{
            $(this).next().css("display","flex");
        }
        
    });
    $('.action-toggle').click(function(event) {
        event.preventDefault();
        var id = $(this).attr('href');
        $(id).toggleClass('toggle');
        
        if($(id).hasClass("toggle")) {  
        
            $(this).find('.text').html(" Show less"); 
            
            //window.location.replace="id";

           

        
        } else {
            $(this).find('.text').html(" Show more");
            scrollToSpecificDiv(".courseinfo-section");
        } 
    });


    //smoothscroll
    $('.smoothscroll').on('click', function(e) {
        e.preventDefault();
        var url = $(this).attr('href');
        window.location.href = url;
        openSpecificDeliveryMethod(url);
      });
});
//tabs Js Function
function tabjsInit() {
    // js tabs
    $(".tabjs").hide();
    $(".tabjs.tabjs-default").show();
}

function tabjsSwitch() {
    event.preventDefault();
    if ($(this).hasClass('active')) {
        return false;
    }
    var targetId = $(this).data("tabjs-target");
    var target = $("#" + targetId);

    target.show().siblings(".tabjs").hide();
    $(this).closest('.tabjs-container').find('.tabjs-switch').removeClass("active");
    $(this).addClass('active');
}
//FAQ's
$(".ques").click(function() {
    $(this).toggleClass("active") ;
    //$(this).next(".coursepanel").toggle();
    $(this).closest('.item').siblings(".item").find(".ques").removeClass("active");
});
//tabs click
tabjsInit();
$(".tabjs-switch").on("click", tabjsSwitch);


function checkConsent(button)
{
    var form = button.closest('form');
    var checkbox = $(form).find("input[name='contactConsent']");
    if(checkbox.length == 0)
    {
        console.log('contact consent field not found');
        return false;
    }
    var error = checkbox.closest('form').find('.consent-error');
    if(checkbox.is(":checked"))
    {
        error.hide();
        return true;
    }
    error.show();
    console.log('contact consent is not checked');
    event.preventDefault();
    return false;
}

//back to top
$(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#scroll').fadeIn(); 
        } else { 
            $('#scroll').fadeOut(); 
        } 
    }); 
    $('#scroll').click(function(){ 
        $("html, body").animate({ scrollTop: 0 }, 600); 
        return false; 
    }); 
});

var loading = $('#loadingajax');
//show and hide loader
$(document).ajaxStart(function () {
   
    loading.css("display", "flex");
  })
.ajaxStop(function () {
   
    loading.hide()
 });

/* filters */

var select = $("#filterSelect").find("select")
function filterSubmit(input)
{
    event.preventDefault();
    var elm = $(input).closest('form');
    var course = $(elm).find("select[name='course']").val();
    var location = $(elm).find("select[name='location']").val();
    var deliveryMethod = $(elm).find("select[name='deliveryMethod']").val();
    if(($(elm).find("select[name='course']").attr("required") == "required" || $(elm).find("select[name='course']").attr("required")==true) && course == "")
    {
        var select = $(elm).find("select[name='course']");
        select.attr('title',"Please select your course");
        select.addClass('error');
        return false;
    }
    if(course == "" && location == "" && deliveryMethod == "")
    {
        var selectCourse = $(elm).find("select[name='course']");
        selectCourse.attr('title',"Please select your course");
        selectCourse.addClass('error');
        var selectLocation = $(elm).find("select[name='location']");
        selectLocation.attr('title',"Please select Location");
        selectLocation.addClass('error');
        var selectDeliveryMethod = $(elm).find("select[name='deliveryMethod']");
        selectDeliveryMethod.attr('title',"Please select Delivery Method");
        selectDeliveryMethod.addClass('error');
        return false;
    }
    $(elm).submit();
}
