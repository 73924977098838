 var mouseDown = {x:0,y:0};
 var mouseUp   = {x:0,y:0};
 var swipeLength = 30;
$(document).ready(function () {

    
    $(window).on('touchstart vmousedown', function( event ){
        mouseDown.x = event.touches[0].clientX;
        mouseDown.y = event.touches[0].clientY;
        mouseUp.x = mouseDown.x;
        mouseUp.y = mouseDown.y;
    });
    
    $(window).on('touchmove vmousemove', function( event ){
        mouseUp.x = event.touches[0].clientX;
        mouseUp.y = event.touches[0].clientY;
    });

    $(window).on('touchend vmouseup', function( event ){

        if((mouseUp.x + swipeLength) < mouseDown.x)
        {
            $(window).trigger('swipeleft');
            // console.log('swipe left ');
        }
        if((mouseUp.x - swipeLength) > mouseDown.x)
        {
            $(window).trigger('swiperight');
            // console.log('swipe right ');
        }
        if((mouseUp.y + swipeLength) < mouseDown.y)
        {
            $(window).trigger('swipeup');
            // console.log('swipe up ');
        }
        if((mouseUp.y - swipeLength) > mouseDown.y)
        {
            $(window).trigger('swipedown');
            // console.log('swipe down ');
        }
    });

    $( window ).on( "swipeleft", function( event ) { 
        
        if(!$('#menuToggle').hasClass('active'))
        {
            // alert(window.innerWidth);
            if(mouseDown.x > window.innerWidth-50)
            toggleMenu();
        }
     } )

    $( window ).on( "swiperight", function( event ) { 
        if($('#menuToggle').hasClass('active'))
        {
            toggleMenu();
        }
     } )
     

    /* close menu when click outside the div */
    $(document).click(function(e){
        if($('#menuToggle').hasClass('active'))
        {
            if ($(e.target).closest(".menu-container").length === 0) {
                toggleMenu();
            }
        }
    });

});

function toggleMenu()
{
    $("#menuToggle").toggleClass('active');
}
